<template>
  <div>
    <!-- Modal Adjunto Foto-->
    <div
      class="modal fade"
      id="modal-cambio-fecha"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" style="width: 450px">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cambio Fecha De Postulación</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="postulacion">
              <div class="row">
                <div class="col-sm-6">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ postulacion.vehiculo }}
                    </h5>
                    <span class="description-text text-secondary fas fa-truck">
                      Vehiculo</span
                    >
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ postulacion.remolque }}
                    </h5>
                    <span
                      class="description-text text-secondary fas fa-truck-loading"
                    >
                      Remolque</span
                    >
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{
                        postulacion.transportadora
                          ? postulacion.transportadora.razon_social
                          : ""
                      }}
                    </h5>
                    <span
                      class="description-text text-secondary fas fa-warehouse"
                    >
                      Transportadora</span
                    >
                  </div>
                </div>
                <div class="col-sm-12">
                  <div class="description-block">
                    <h5 class="description-header">
                      {{ postulacion.fecha_cargue }}
                    </h5>
                    <span
                      class="description-text text-secondary fas fa-calendar-day"
                    >
                      Fecha Cargue</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer" v-if="$parent.oferta">
              <span class="text-center" style="font-size: 19px"
                >Fechas disponibles</span
              >
              <div
                v-for="detalle in $parent.oferta.detalles"
                :key="detalle.id"
                class="text-center"
              >
                <div
                  v-if="
                    detalle.fecha >= $parent.hoy &&
                    detalle.postulaciones.length < detalle.cant_aceptada
                  "
                  class="btn btn-ms mt-1 mx-auto"
                  style="width: 380px"
                  :class="check == detalle.id ? 'bg-success' : 'bg-warning'"
                  @click="seleccionar(detalle.id, detalle.fecha)"
                >
                  {{ detalle.fecha }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "PostulacionCambioFecha",
  components: {},
  data() {
    return {
      postulacion: {},
      check: null,
    };
  },

  methods: {
    cargarDatos(pos) {
      this.postulacion = pos;
    },

    seleccionar(id, fecha) {
      this.check = null;
      this.check = id;
      let data = {
        id: this.postulacion.id,
        com_oferta_subasta_det_id: id,

        fecha_cargue: fecha + " " + this.postulacion.fecha_cargue.split(" ")[1],
      };

      this.$swal({
        title: "Está seguro de cambiar la postulación?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si",
      }).then(async (result) => {
        if (result.value) {
          this.cargando = true;
          await axios
            .post("/api/com/postulaciones/cambioFecha", data)
            .then(() => {
              this.cargando = false;

              this.$swal({
                icon: "success",
                title:
                  "Se cambio de fecha de postulacion del vehiculo: " +
                  this.postulacion.vehiculo +
                  " a la fecha:" +
                  fecha,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.$refs.closeModal.click();
              this.$parent.getIndex();
            })
            .catch((e) => {
              this.cargando = false;
              this.$swal({
                icon: "error",
                title: "Ocurrió un error, vuelva a intentarlo..." + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            });
        }
      });
    },
  },
};
</script>
